import axios from 'axios'
import {config} from '../../config';

export const reportService = {
    generateAllClientsReport,
    getAllClientsReportName,
    downloadBase64File
}

let reportUrl = config.apiUrl + "/query/reports";

async function generateAllClientsReport(){
    var token = localStorage.getItem('token')
    return await axios.get(reportUrl, {
        headers:{
            'Authorization': 'Bearer ' + token
        }
    })
}

function downloadBase64File(contentBase64, fileName) {
    const linkSource = `data:application/pdf;base64,${contentBase64}`;
    const downloadLink = document.createElement('a');
    document.body.appendChild(downloadLink);

    downloadLink.href = linkSource;
    downloadLink.target = '_self';
    downloadLink.download = fileName;
    downloadLink.click(); 
}

function getAllClientsReportName(){
    const monthNames = ["January", "February", "March", "April", "May", "June",
    "July", "August", "September", "October", "November", "December"];
    const dateObj = new Date();
    const month = monthNames[dateObj.getMonth()];
    const day = String(dateObj.getDate()).padStart(2, '0');
    const year = dateObj.getFullYear();
    const output = 'clientsReport' + month + day  + year + '.csv';

    return output;
    //continue here
}



