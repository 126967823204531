import axios from 'axios';
import router from '../router/index'
import {config} from '../../config';

export const userService = {
    login,
    logout,
    register,
    registerEnterpriseAccount,
    updateProfile,
    getProfile,
    getIfAdmin,
    sendResetPasswordEmail,
    submitPasswordReset,
    getIfManager,
    validatePasswordRequirements,
    submitPasswordChange,
    checkResetPasswordToken
};

let loginUrl = config.apiUrl + "/command/authenticate/login";
let registerUrl = config.apiUrl + "/command/authenticate/register"
let userProfileCommandUrl = config.apiUrl + "/command/user"
let userProfileQueryUrl = config.apiUrl + "/query/user"
let isAuthenticateUrl = config.apiUrl + "/query/authenticate/getifadmin"
let resetEmailUrl = config.apiUrl + "/command/authenticate/resetpassword"
let changePasswordUrl = config.apiUrl + "/command/user/changepassword"
let resetVerifyUrl = config.apiUrl + "/command/authenticate/checktoken"
let userRoleUrl = config.apiUrl + "/query/authenticate/getrole"

function validatePasswordRequirements(password){
    if(!password) return false;
    if(password.length < 6) return false;
    if(
        !password.includes('a') && !password.includes('b')
        && !password.includes('c') && !password.includes('d')
        && !password.includes('e') && !password.includes('f')
        && !password.includes('g') && !password.includes('h')
        && !password.includes('i') && !password.includes('j')
        && !password.includes('k') && !password.includes('l')
        && !password.includes('m') && !password.includes('n')
        && !password.includes('o') && !password.includes('p')
        && !password.includes('q') && !password.includes('r')
        && !password.includes('s') && !password.includes('t')
        && !password.includes('u') && !password.includes('v')
        && !password.includes('w') && !password.includes('x')
        && !password.includes('y') && !password.includes('z')
        )
        {
            return false;
        }
    if(
        !password.includes('A') && !password.includes('B')
        && !password.includes('C') && !password.includes('D')
        && !password.includes('E') && !password.includes('F')
        && !password.includes('G') && !password.includes('H')
        && !password.includes('I') && !password.includes('J')
        && !password.includes('K') && !password.includes('L')
        && !password.includes('M') && !password.includes('N')
        && !password.includes('O') && !password.includes('P')
        && !password.includes('Q') && !password.includes('R')
        && !password.includes('S') && !password.includes('T')
        && !password.includes('U') && !password.includes('V')
        && !password.includes('W') && !password.includes('X')
        && !password.includes('Y') && !password.includes('Z')
    )
    {
        return false;
    }
    if(
        !password.includes('~') && !password.includes('`') &&
        !password.includes('!') && !password.includes('@') &&
        !password.includes('#') && !password.includes('$') &&
        !password.includes('%') && !password.includes('^') &&
        !password.includes('&') && !password.includes('*') &&
        !password.includes('(') && !password.includes(')') &&
        !password.includes('_') && !password.includes('-') &&
        !password.includes('+') && !password.includes('=') &&
        !password.includes('[') && !password.includes(']') &&
        !password.includes('{') && !password.includes('}') &&
        !password.includes(';') && !password.includes(':') &&
        !password.includes('"') && !password.includes("'") &&
        !password.includes(',') && !password.includes('<') &&
        !password.includes('>') && !password.includes('.') &&
        !password.includes('/') && !password.includes('?')
    )
    {
        return false;
    }
    return true;
}

async function getIfAdmin(){
    const token = getToken();
    return await axios.get(isAuthenticateUrl, {
        headers:{
            'Authorization': 'Bearer ' + token
        }
    })
}

async function getProfile(){
    const token = getToken();
    return await axios.get(userProfileQueryUrl, {
        headers:{
            'Authorization': 'Bearer ' + token
        }
    })
}

async function sendResetPasswordEmail(email){
    return await axios.post(resetEmailUrl + `/${email}`)
}

async function submitPasswordReset(token, email, newPassword){
    const model = {};
    model.Token = token;
    model.Email = email;
    model.NewPassword = newPassword;
    return await axios.post(resetEmailUrl, model)
}


async function submitPasswordChange(currentPassword, newPassword, submitPassword){
    const model = {};
    const token = getToken();
    model.CurrentPassword = currentPassword;
    model.NewPassword = newPassword;
    model.ConfirmNewPassword = submitPassword;
    console.log(model, changePasswordUrl);
    return await axios.post(changePasswordUrl, model, {
        headers:{
            'Authorization': 'Bearer ' + token
        }
    })
}

async function checkResetPasswordToken(token, email){
    const model = {};
    model.Token = token;
    model.Email = email;
    return await axios.post(resetVerifyUrl, model)

}

async function updateProfile(userProfile){
    const token = getToken();
    return await axios.post(userProfileCommandUrl, userProfile, {
        headers:{
            'Authorization': 'Bearer ' + token
        }
    })
}

async function login(username, password){
    return await axios.post(loginUrl, {
        userName: username,
        password: password
    })
}

function logout(){
    localStorage.removeItem('token');
    router.push('/');
}

function register(email, password, firstName, lastName){
    return axios.post(registerUrl, {
        userName: email,
        email: email,
        password: password,
        firstName: firstName,
        lastName: lastName
    })
}

function registerEnterpriseAccount(user){
    return axios.post(registerUrl, user)
}

async function getIfManager(){
    const token = getToken();
    return await axios.get(userRoleUrl, {
        headers:{
            'Authorization': 'Bearer ' + token
        }
    })
}

function getToken() {
  const viewAsUserAccessToken = sessionStorage.getItem('ViewAsUserAccessToken');

  if(!viewAsUserAccessToken) return localStorage.getItem('token')

  return viewAsUserAccessToken;
}
