
import { documentToHtmlString } from '@contentful/rich-text-html-renderer';
import {config} from '../../config';


export const contentService = {
    getFaqs,
    getRecommendationDefinitionsByRoomName
};

async function getFaqs(){
    const contentful = require('contentful');
    var returnList = []

    const client = contentful.createClient({
      space: config.contentfulSpace,
      accessToken: config.contentfulAccessToken,
      environment: config.contentfulEnvironmentName
    });

    var result = (await client.getEntries({content_type: 'hflFaq', limit:1000})).items;
    for(var i = 0; i < result.length; i++){
      var faq = {question: result[i].fields.question, answer: documentToHtmlString(result[i].fields.answer)}
      returnList.push(faq);
    }

    return returnList;
}

async function getRecommendationDefinitionsByRoomName(roomName){
  const contentful = require('contentful');
    var returnList = []

    const client = contentful.createClient({
      space: config.contentfulSpace,
      accessToken: config.contentfulAccessToken,
      environment: config.contentfulEnvironmentName
    });

    var result = (await client.getEntries({content_type: 'hflRoomRecommendationDefinition', limit:1000})).items;
    let rec;
    for(var i = 0; i < result.length; i++){
      if(result[i].fields.roomTypeName.toLowerCase() == roomName.toLowerCase()){
         rec =
        {
          name: result[i].fields.name,
          roomTypeName: result[i].fields.roomTypeName,
          category: result[i].fields.category,
          image: result[i].fields.image ? result[i].fields.image.fields.file.url : ''
        }
        returnList.push(rec);
      } else {
        if (result[i].fields.category === "Assistive Tech" && !returnList.find(record =>
          record.name.trim().toLowerCase() === result[i].fields.name.trim().toLowerCase())) {
          const rec = {
            name: result[i].fields.name,
            roomTypeName: roomName,
            category: result[i].fields.category,
            image: result[i].fields.image ? result[i].fields.image.fields.file.url : ''
        };
        returnList.push(rec)
        }
      }
    }

    return returnList;
}
