import axios from 'axios';
import {config}  from '../../config';
export const adminService = {
  // still need edit, delete options
  addPartnerOrganization,
  addRoomPropertyDefinition,
  addRoomRecommendationDefinition,
  saveRoomRecommendationPhoto,
  getAllRoomRecommendationDefinitions,
  getAllRoomPropertyDefinitions,
  getAllPartnerOrganizations,
  getAllFailureAlerts,
  getAllUsersByPartnerId,
  getAllDeletedClients,
  getAllDeletedPartners,
  getAllDeletedPartnerUsers,
  getRecommendationPhoto,
  restoreClient,
  restorePartner,
  restorePartnerUser,
  editExistingPartner,
  editExistingPartnerUser,
  editRoomRecommendation,
  deleteRoomRecommendationDefinition,
  deleteRoomPropertyDefinition,
  deletePartnerOrganization,
  deletePartnerUser,
  deleteFailureAlert,
  toggleActivation,
  changeUserPassword,
  viewAsUser,
};


let baseQueryUrl = config.apiUrl + "/query/admin";
let baseCommandUrl = config.apiUrl + "/command/admin"

async function getAllRoomRecommendationDefinitions(){
    const token = getToken();
        return await axios.get(baseQueryUrl + "/recommendations", {
            headers:{
                'Authorization': 'Bearer ' + token
            }
        })
}

async function restoreClient(id){
    const token = getToken();
    return await axios.post(baseCommandUrl + `/deleted-clients/${id}`, null, {
        headers:{
            'Authorization': 'Bearer ' + token
        }
    })
}

async function toggleActivation(id, shouldActivate){
    const token = getToken();

    const url = shouldActivate ? baseCommandUrl + `/user/reactivate/${id}` : baseCommandUrl + `/user/deactivate/${id}`;
    return await axios.post(url, null, {
        headers:{
            'Authorization': 'Bearer ' + token
        }
    })
}

async function restorePartner(id){
    const token = getToken();
    return await axios.post(baseCommandUrl + `/deleted-partners/${id}`, null, {
        headers:{
            'Authorization': 'Bearer ' + token
        }
    })
}

async function saveRoomRecommendationPhoto(recommendationId, formData){
    const token = getToken();
    return await axios.post(baseCommandUrl + `/recommendations/${recommendationId}/image`, formData, {
        headers:{
            'Authorization': 'Bearer ' + token
        }
    })
}

async function getRecommendationPhoto(recommendationId){
    const token = getToken();
    return await axios.get(baseQueryUrl + `/recommendations/${recommendationId}/image`, {
        headers:{
            'Authorization': 'Bearer ' + token
        }
    })
}

async function restorePartnerUser(id){
    const token = getToken();
    return await axios.post(baseCommandUrl + `/deleted-partnerusers/${id}`, null, {
        headers:{
            'Authorization': 'Bearer ' + token
        }
    })
}

async function getAllDeletedClients(){
    const token = getToken();
    return await axios.get(baseQueryUrl + "/deleted-clients", {
        headers:{
            'Authorization': 'Bearer ' + token
        }
    })
}

async function getAllDeletedPartners(){
    const token = getToken();
    return await axios.get(baseQueryUrl + "/deleted-partners", {
        headers:{
            'Authorization': 'Bearer ' + token
        }
    })
}

async function getAllDeletedPartnerUsers(){
    const token = getToken();
    return await axios.get(baseQueryUrl + "/deleted-partnerusers", {
        headers:{
            'Authorization': 'Bearer ' + token
        }
    })
}

async function deletePartnerUser(user, partnerId){
    const token = getToken();
    return await axios.delete(baseCommandUrl + `/partners/${partnerId}/users/${user.userId}`, {
        headers:{
            'Authorization': 'Bearer ' + token
        }
    })
}


async function getAllUsersByPartnerId(partnerId){
    const token = getToken();
    return await axios.get(baseQueryUrl + `/partners/${partnerId}/users`, {
        headers:{
            'Authorization': 'Bearer ' + token
        }
    })
}

async function editExistingPartnerUser(user, partnerId){
    const token = getToken();
    return await axios.put(baseCommandUrl + `/partners/${partnerId}/users/${user.userId}`, user, {
        headers:{
            'Authorization': 'Bearer ' + token
        }
    })
}

async function deleteRoomRecommendationDefinition(item){
    const token = getToken();
        return await axios.delete(baseCommandUrl + `/recommendations/${item.id}`, {
            headers:{
                'Authorization': 'Bearer ' + token
            }
        })
}

async function editExistingPartner(partner){
    const token = getToken();
    return await axios.put(baseCommandUrl + `/partners/${partner.id}`, partner, {
        headers:{
            'Authorization': 'Bearer ' + token
        }
    })
}

async function editRoomRecommendation(recommendation){
    const token = getToken();
    return await axios.put(baseCommandUrl + `/recommendations/${recommendation.id}`, recommendation, {
        headers:{
            'Authorization': 'Bearer ' + token
        }
    })
}

async function getAllFailureAlerts(){
    const token = getToken();
        return await axios.get(baseQueryUrl + "/alerts", {
            headers:{
                'Authorization': 'Bearer ' + token
            }
        })
}

async function deleteFailureAlert(item){
    const token = getToken();
        return await axios.delete(baseCommandUrl + "/alerts", item, {
            headers:{
                'Authorization': 'Bearer ' + token
            }
        })
}

async function getAllPartnerOrganizations(){
    const token = getToken();
        return await axios.get(baseQueryUrl + "/partners", {
            headers:{
                'Authorization': 'Bearer ' + token
            }
        })
}

async function deletePartnerOrganization(id){
    const token = getToken();
        return await axios.delete(baseCommandUrl + `/partners/${id}`, {
            headers:{
                'Authorization': 'Bearer ' + token
            }
        })
}

async function getAllRoomPropertyDefinitions(){
    const token = getToken();
        return await axios.get(baseQueryUrl + "/properties", {
            headers:{
                'Authorization': 'Bearer ' + token
            }
        })
}

async function deleteRoomPropertyDefinition(item){
    const token = getToken();
        return await axios.delete(baseCommandUrl + `/properties/${item.id}`, item, {
            headers:{
                'Authorization': 'Bearer ' + token
            }
        })
}

async function addPartnerOrganization(partner){
    const token = getToken();
        return await axios.post(baseCommandUrl + "/partners", partner, {
            headers:{
                'Authorization': 'Bearer ' + token
            }
        })
}

async function addRoomPropertyDefinition(roomProperty){
    const token = getToken();
        return await axios.post(baseCommandUrl + "/properties", roomProperty, {
            headers:{
                'Authorization': 'Bearer ' + token
            }
        })
}

async function addRoomRecommendationDefinition(roomRecommendation){
    const token = getToken();
    return await axios.post(baseCommandUrl + "/recommendations", roomRecommendation, {
        headers:{
            'Authorization': 'Bearer ' + token
        }
    })
}

async function changeUserPassword(passwordModel){
  const token = getToken();
  const changeModel = {
    'email': passwordModel.contactEmail,
    'newPassword': passwordModel.newPassword
  };
  return await axios.post(baseCommandUrl + "/ChangeUserPassword", changeModel, {
    headers:{
      'Authorization': 'Bearer ' + token
    }
  })
}

async function viewAsUser(user){
  const token = getToken();
  const requestModel = {
    'userId': user.userId,
    'supportKey': user.supportKey,
    'email': user.contactEmail
  };

  return await axios.post(baseCommandUrl + "/loginWithSupportKey", requestModel, {
    headers: {
      'Authorization': 'Bearer ' + token
    }
  })
}

function getToken() {
  const viewAsUserAccessToken = sessionStorage.getItem('ViewAsUserAccessToken');

  if(!viewAsUserAccessToken) return localStorage.getItem('token');

  return viewAsUserAccessToken;
}
