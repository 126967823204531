<template>
  <div class="row" id="footer-row" style="margin-top:500px;">
    <div class="col-md-2"></div>
    <div role="contentinfo" class="col-md-8">&#169;Copyright {{currentYear}} by Home for Life Design. All Rights Reserved 
        <!-- | <router-link to="/privacy">Privacy Statement</router-link> -->
        | <a href="https://hflstorage.blob.core.windows.net/documents/Privacy Statement.pdf">Privacy Statement</a>
        <!-- | <router-link to="/terms">Terms of Use</router-link> -->
        | <a href="https://hflstorage.blob.core.windows.net/documents/Terms of Service and License Agreement.pdf">Terms of Use</a>

    </div>
  </div>
</template>

<script>
export default {
  data(){
    return{
      currentYear: new Date().getFullYear()
    }
  }
}
</script>

<style>
#footer-row{
    background-color: rgba(0, 0, 0, 0.829);
    color: white !important;
    margin-top:250px;
    padding-top:15px;
    padding-bottom:15px;

}
</style>