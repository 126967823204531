<template>
  <div>
      <div class="row">
          <div class="col-md-6">
              <LandingLeftBox/>
          </div>
          <div class="col-md-6">
              <LandingRightBox/>
          </div>
      </div>
  </div>
</template>

<script>
import LandingLeftBox from '../LandingPage/LandingLeftBox'
import LandingRightBox from '../LandingPage/LandingRightBox'
export default {
components:{
    LandingLeftBox,
    LandingRightBox
}
}
</script>

<style>

</style>