import axios from 'axios';
import {config} from '../../config';
export const clientService = {
    createClient,
    getAllClients,
    deleteClient,
    getClientById,
    editClient,
    saveConcern,
    getAllClientConcerns,
    getConcernNameFromKey,
    deleteConcern,
    getBoolFromStr,
    updateStatus,
    getOrganizationLogo
};

let clientQueryUrl = config.apiUrl + "/query/clients";
let clientCommandUrl = config.apiUrl + "/command/clients";
let imageQueryUrl = config.apiUrl + "/query/organization/organizationlogo";

async function createClient(newClient, thirdPartyIdentifier){
    const token = getToken();
    const query = `?thirdPartyIdentifier=${thirdPartyIdentifier || ""}`;
    return await axios.post(clientCommandUrl + query, newClient, {
        headers: {
            'Authorization': 'Bearer ' + token
        }
    })
}

async function updateStatus(client){
    const token = getToken();
    return await axios.put(clientCommandUrl + `/${client.id}/status`, client, {
        headers:{
            'Authorization': 'Bearer ' + token
        }
    })
}

async function getOrganizationLogo(){
    const token = getToken();
    return await axios.get(imageQueryUrl, {
        headers:{
            'Authorization': 'Bearer ' + token
        }
    })
}

async function getAllClients(){
    const token = getToken();
    return await axios.get(clientQueryUrl, {
        headers: {
            'Authorization': 'Bearer ' + token
        }
    })
}

async function deleteClient(client){

    const token = getToken();
    return await axios.delete(clientCommandUrl + `/${client.id}`, {
        headers: {
            'Authorization': 'Bearer ' + token
        }
    })
}

async function getClientById(id){
    const token = getToken();
    return await axios.get(clientQueryUrl + `/${id}`, {
        headers: {
            'Authorization': 'Bearer ' + token
        }
    })
}

async function editClient(client){
    const token = getToken();
    return await axios.put(clientCommandUrl + `/${client.id}`, client, {
        headers:{
            'Authorization': 'Bearer ' + token
        }
    })
}

async function saveConcern(concernModel){
    const token = getToken();
    const url = clientCommandUrl + `/${concernModel.clientId}/specialconsiderations`
    return await axios.post(url, concernModel, {
        headers:{
            'Authorization': 'Bearer ' + token
        }
    })
}

async function getAllClientConcerns(clientId){
    const token = getToken();
    const url = clientQueryUrl + `/${clientId}/specialconsiderations`
    return await axios.get(url, {
        headers:{
            'Authorization': 'Bearer ' + token
        }
    })
}

async function deleteConcern(clientId, id){
    const token = getToken();
    const url = clientCommandUrl + `/${id}/specialconsiderations/${clientId}`
    return await axios.delete(url, {
        headers:{
            'Authorization': 'Bearer ' + token
        }
    })
}

function getBoolFromStr (strBool) {
  return strBool == "true" || strBool == "True" || strBool == "TRUE";
}

function getConcernNameFromKey(concernKey){
    switch(concernKey){
        case 'falls':
            return "Has a History of Falls";
        case 'caregiver':
            return "Has a Caregiver";
        case 'memoryImpairment':
            return "Has Memory Impairment";
        case 'neurologicalIssues':
            return "Has Neurological Issues";
        case 'orthopedicIssues':
            return "Has Orthopedic Issues";
        case 'visualDefects':
            return "Has Visual Defects";
        case 'pets':
            return "Has Pets";
        case 'other1':
            return "Other Concern 1";
        case 'other2':
            return "Other Concern 2";
        case 'other3':
            return "Other Concern 3";
        case 'other4':
            return "Other Concern 4";
        case 'other5':
            return "Other Concern 5";
        default:
            return "Other Concern";
    }
}

function getToken() {
  const viewAsUserAccessToken = sessionStorage.getItem('ViewAsUserAccessToken');

  if(!viewAsUserAccessToken) return localStorage.getItem('token');

  return viewAsUserAccessToken;
}
