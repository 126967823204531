import axios from 'axios';
import {config} from '../../config';

export const managerService = {
    getOrganizationDetails,
    getOrganizationUsers,
    editOrganization,
    deleteUser,
    toggleManager,
    uploadOrganizationLogo

};

let baseQueryUrl = config.apiUrl + "/query/enterprisemanager";
let baseCommandUrl = config.apiUrl + "/command/enterprisemanager";

 async function getOrganizationDetails(){
    const token = getToken();
    return await axios.get(baseQueryUrl + '/organization', {
        headers: {
            'Authorization': 'Bearer ' + token
        }
    })
}

async function getOrganizationUsers(){
    const token = getToken();
    return await axios.get(baseQueryUrl + '/users', {
        headers: {
            'Authorization': 'Bearer ' + token
        }
    })
}

async function editOrganization(organization){
    const token = getToken();
    return await axios.put(baseCommandUrl + '/organization', organization, {
        headers: {
            'Authorization': 'Bearer ' + token
        }
    })
}

async function deleteUser(user){
    const token = getToken();
    return await axios.post(baseCommandUrl + `/users/${user.userId}`, user, {
        headers: {
            'Authorization': 'Bearer ' + token
        }
    })
}

async function uploadOrganizationLogo(organizationId, formData){
    const token = getToken();
        return await axios.post(baseCommandUrl + `/organization/${organizationId}/uploadLogo`, formData, {
            headers:{
                'Authorization': 'Bearer ' + token
            }
        })
}

async function toggleManager(user, shouldMakeManager){
    const token = getToken();
    if(shouldMakeManager){
        return await axios.get(baseCommandUrl + `/users/${user.userId}/add`, {
            headers: {
                'Authorization': 'Bearer ' + token
            }
        })
    }
    else{

        return await axios.get(baseCommandUrl + `/users/${user.userId}/remove`, {
            headers: {
                'Authorization': 'Bearer ' + token
            }
        })
    }

}

function getToken() {
  const viewAsUserAccessToken = sessionStorage.getItem('ViewAsUserAccessToken');

  if(!viewAsUserAccessToken) return localStorage.getItem('token');

  return viewAsUserAccessToken;
}
