<template>
<!-- Add v-show here to show whether site is logged in or not, show correct navbar accordingly -->
  <div id="app" class="container-fluid">
    
    <!-- <TopNavBar/> -->
    <div>
       <router-view></router-view>

    </div>
   
    <Footer/>
  </div>
</template>

<script>
import Footer from '../src/components/Footer'
//import TopNavBar from '../src/components/LandingPage/TopNavBar'

export default{
  components:{
    Footer,
    //TopNavBar
  }
}

</script>
<style>
.form-control:focus {
  border-color: #b4d88c!important;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px #b4d88c !important;
}

/*Add text-align center for right column input fields for large screen */
#entregister-form-header-row{
    
    border-bottom:3px solid #b4d88c;
    
    padding: 3px 10px 6px 12px;
    font-weight: bold;
    margin-bottom:40px;
}
#entregistration-code-label{
 color: #31708f;
}

#promo-code-label{
    color: #31708f;
}

#enterprise-label-row{
    color: #7A7A7A;
    margin-bottom:20px;
    
}

a{
    text-decoration:none !important;
}
a:hover{
    text-decoration:none !important;
}
#main-nav-bar{
    margin-bottom:50px;
}

/* Full Screen Nav */
@media only screen and (min-width: 1000px) {
  #landing-right-link{
    margin-right:310px;
    border-right: 1px dashed lightgrey;
  }
  #brand-nav-bar{
      margin-left:200px;
  }
  .border-btn{
    
    border-left:1px dashed lightgrey !important;
}
  .nav-link{
      color: darkslategray !important;
     
  }
  .nav-link:hover{
      background-color:#6b6b6b;
      color:white !important;
      font-weight:bold !important;
      border:none !important;
  }
 
  
}

@media only screen and (max-width: 400px) {
  .navbar-toggler{
      float:right;
  }
}
@media only screen and (max-width: 800px) {
  .nav-item{
      background-color:#6b6b6b !important;
      margin-bottom:2px;
      
  }
  .nav-link{
      color: white !important;
      margin-left:5px;
  }
}

.btn-primary{
    background-color: #428bca !important;
    border-color: #428bca !important;
}
.btn-primary:hover{
    opacity:0.7 !important;
}
.purple-title{
    color: #6c207e !important;
    font-weight: bold !important;
    font-family: 'MyriadPro',Arial, Tahoma, sans-serif !important; 
}
#terms-title{
    color:#6c207e !important;
}
li{
    margin-bottom:10px;
}
.purple-btn{
     border-color: #6c207e !important;
     background-color:#6c207e !important;
     color: white !important;
}
.purple-btn:hover, .purple-btn-outline:hover, .green-btn:hover{
    opacity: 0.6;
}
.purple-btn-outline{
  border-color: #6c207e !important;
  background-color: white !important;
  color: #6c207e !important;
}
.green-btn{
  border-color: #b4d88c !important;
  background-color: #b4d88c !important;
  color: white !important;
}
.text-center{
    text-align: center;
}

.btn-primary{
  background-color: #1266f1 !important;
}

.btn-shadow{
  box-shadow: 0 2px 5px 0 rgb(0 0 0 / 20%), 0 2px 10px 0 rgb(0 0 0 / 10%);
}

.btn-warning{
  background-color: #FFA900 !important;
}

.btn{
  font-size: .75rem !important;
}

.btn-success{
  background-color: #00b74a !important;
  
}
</style>
