import Vue from 'vue'
import Vuex from 'vuex'


Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    therapist: {},
    clientsArray:[],
    isLoggedIn: localStorage.getItem('token') ? true : false
  },
  mutations: {
    setIsLoggedIn(state, val){
      state.isLoggedIn = val;
    }
  },
  getters: {
    isLoggedIn: state => {
      return state.isLoggedIn
    }
  },
  actions: {
  },
  modules: {
  }
})
